<template>
    <footer>
	<div class="container">
	<div class="footer-main">
		<div class="flex-box">
			<div class="logo-img">
				<a @click="goToHome()">
                    <img src="../assets/images/footer_logo.png" alt="">
                </a>
			</div>
			<p>Our driving force is excellence, therefore, we always strive to outdo our customers' expectations. </p>
			<ul>
				<li><a href="https://www.facebook.com/Seattle-Choice-Transport-LLC-103052518911547" target="_blank"><img src="../assets/images/facebook.png" alt=""></a></li>
				<li><a href="https://twitter.com/choice_seattle" target="_blank"><img src="../assets/images/twitter.png" alt=""></a></li>
				<li><a href="https://www.instagram.com/seattlechoice" target="_blank"><img src="../assets/images/instagram.png" alt=""></a></li>
				<li><a href="https://www.linkedin.com/company/seattle-choice-transport-llc/mycompany/?viewAsMember=true" target="_blank"><img src="../assets/images/linkdian.png" alt=""></a></li>
			</ul>
		</div>
		<div class="settle-list s-w">
			<h6>Seattle Choice</h6>
			<ul>
				<li><a @click="goToAbout()">About Us</a></li>
				<!-- <li><a @click="goToBecomeDriver()">Become a Driver</a></li> -->
				<li><a @click="goToHome()" href="#our-feet">Our Fleet</a></li>
				<li><a @click="goToService()">Services</a></li>
			</ul>
		</div>
		<div class="support-list s-w">
			<h6>support</h6>
			<ul>
				<li><a @click="goToContact()">Support Center</a></li>
				<li><a @click="goToPolicy()">Privacy Policy</a></li>
			</ul>
		</div>
		<div class="contact-list s-w">
			<h6>CONTACT</h6>
			<ul>
				<li><img src="../assets/images/location.png" alt="">2800 Bluff Rd Indianapolis, IN, 46225</li>
				<li><a href="mailto:info@seattlechoicetransport.com"><img src="../assets/images/mail.png" alt="">info@seattlechoicetransport.com</a></li>
				<li><a href="tel:+1 (317) 536-8005"><img src="../assets/images/call.png" alt="">+1 (317) 536-8005</a></li>
				<li>By contacting us, you agree to receive informational text messages. You may opt out of receiving text messages (SMS) at any time by replying to STOP to any message.</li>
			</ul>
		</div>
	</div>
	</div>
	<div class="copyright">
		<p>Copyright {{ new Date().getFullYear() }} Seattle Choice Transport</p>
	</div>
    </footer>
</template>

<script>
export default {
    name: "Footer",
	methods: {
		goToHome(){
            this.$router.push({ path: "/" });
        },
        goToAbout(){
            this.$router.push({ path: "/about" });
        },
        goToService(){
            this.$router.push({ path: "/services" });
        },
        goToBecomeDriver(){
            this.$router.push({ path: "/become-driver" });
        },
		goToContact(){
            this.$router.push({ path: "/contact" });
        },
		goToPolicy(){
            this.$router.push({ path: "/policy" });
        },
    }
}
</script>
